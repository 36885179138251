import { ENVIRONMENT } from '@agdir/environment/angular';
import { Environment } from '@agdir/environment/domain';
import { PageComponent, PageContentComponent, PageHeaderComponent } from '@agdir/layout';
import { AuthService, CUSTOMER_PATHS } from '@agdir/services';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { map, Observable } from 'rxjs';

@Component({
	standalone: true,
	selector: 'agdir-404-page',
	template: `
		<agdir-page>
			<agdir-page-header title="404" />
			<agdir-page-content>
				<div class="ag-grid-full">
					<div class="text-lg mb-10 pb-10 border-b">
						Page
						<code class="font-mono">{{ currentPath }}</code>
						was not found.
					</div>
					<div class="grid gap-5 grid-cols-1">
						<ng-container *ngIf="isAuthenticated$ | async; else anonymousTemplate">
							<a class="text-link" href="{{ CUSTOMER_PATHS.ALL_FARMS }}">Go to dashboard</a>
							<a class="text-link" [attr.href]="CUSTOMER_PATHS.AUTH_SIGNOUT">Log out</a>
						</ng-container>
					</div>
					<ng-template #anonymousTemplate>
						<a class="text-link" [attr.href]="CUSTOMER_PATHS.AUTH_SIGNIN">Go to sign-in</a>
					</ng-template>
				</div>
			</agdir-page-content>
		</agdir-page>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgIf, AsyncPipe, PageComponent, PageContentComponent, PageHeaderComponent],
})
export class Error404Component {
	private auth = inject(AuthService);
	private env = inject<Environment>(ENVIRONMENT);
	currentPath = window.location.href;
	isAuthenticated$: Observable<boolean> = this.auth.getCurrentCustomerSession().pipe(map((u) => !!u?.cognitoUser));
	CUSTOMER_PATHS = CUSTOMER_PATHS;
}
